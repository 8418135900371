var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"information-wrapper"},[_c('white-card',{attrs:{"title":"满意度"}},[_c('BaseChart',{ref:"chart",attrs:{"chartOption":_vm.option,"height":'350px'}}),_c('a-divider',{attrs:{"dashed":""}}),_c('BaseChart',{ref:"chart1",attrs:{"chartOption":_vm.option1,"height":'350px'}})],1),_c('a-divider'),_c('white-card',{attrs:{"title":"告警率"}},[_c('BaseChart',{ref:"chart2",attrs:{"chartOption":_vm.option2,"height":'350px'}})],1),_c('a-divider'),_c('white-card',{attrs:{"title":"满意/不满意的原因"}},[_c('a-table',{attrs:{"loading":_vm.loading,"pagination":{
                 showTotal: (total) => `总条数：${total}`,
                 pageSizeOptions: ['10', '20', '50', '100'],
                 total: _vm.total,
                 showSizeChanger: true,
                 pageSize: _vm.per_page,
               },"row-key":"id","data-source":_vm.dataSource}},[_c('a-table-column',{key:"id",attrs:{"data-index":"id","title":"序号"}}),_c('a-table-column',{key:"title",attrs:{"data-index":"title"},scopedSlots:_vm._u([{key:"title",fn:function({ setSelectedKeys, selectedKeys }){return [_c('a-dropdown',[_c('a',{staticStyle:{"color":"#2F54EB"},on:{"click":(e) => e.preventDefault()}},[_vm._v(" "+_vm._s(_vm.tableOption1.find(x => x.value == _vm.type)?.title)+" "),_c('a-icon',{attrs:{"type":"down"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},_vm._l((_vm.tableOption1),function(item){return _c('a-menu-item',{key:item.value},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":() => {
                      _vm.type = item.value;
                      _vm.getTableData(_vm.type);
                    }}},[_vm._v(_vm._s(item.title))])])}),1)],1)]}}])}),_c('a-table-column',{key:"value",attrs:{"data-index":"value"},scopedSlots:_vm._u([{key:"title",fn:function({ setSelectedKeys, selectedKeys }){return [_c('a-dropdown',[_c('a',{staticStyle:{"color":"#2F54EB"},on:{"click":(e) => e.preventDefault()}},[_vm._v(" "+_vm._s(_vm.tableOption2.find(x => x.value == _vm.reasonType)?.name)+" "),_c('a-icon',{attrs:{"type":"down"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},_vm._l((_vm.tableOption2),function(item){return _c('a-menu-item',{key:item.value},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":() => {
                      _vm.reasonType = item.value;
                      _vm.getTableData(_vm.reasonType);
                    }}},[_vm._v(_vm._s(item.name))])])}),1)],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }