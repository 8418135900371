<template>
  <div class="information-wrapper">
    <white-card title="满意度">
      <BaseChart ref="chart" :chartOption="option" :height="'350px'"/>
      <a-divider dashed></a-divider>
      <BaseChart ref="chart1" :chartOption="option1" :height="'350px'"/>
    </white-card>
    <a-divider></a-divider>
    <white-card title="告警率">
      <BaseChart ref="chart2" :chartOption="option2" :height="'350px'"/>
    </white-card>
    <a-divider></a-divider>
    <white-card title="满意/不满意的原因">
      <a-table :loading="loading"
               :pagination="{
                   showTotal: (total) => `总条数：${total}`,
                   pageSizeOptions: ['10', '20', '50', '100'],
                   total: total,
                   showSizeChanger: true,
                   pageSize: per_page,
                 }"
               row-key="id"
               :data-source="dataSource">
          <a-table-column key="id" data-index="id" title="序号">
          </a-table-column>
          <a-table-column key="title" data-index="title">
            <template slot="title" slot-scope="{ setSelectedKeys, selectedKeys }">
              <!-- <a-select v-model="type" style="width: 150px" @change="getTableData">
                <a-select-option v-for="item of tableOption1" :key="item.value" :value="item.value">
                  {{ item.title }}
                </a-select-option>
              </a-select> -->
              <a-dropdown>
                <a @click="(e) => e.preventDefault()" style="color: #2F54EB;">
                  {{ tableOption1.find(x => x.value == type)?.title }} <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item v-for="item in tableOption1" :key="item.value">
                    <a
                      href="javascript:;"
                      @click="() => {
                        type = item.value;
                        getTableData(type);
                      }"
                      >{{ item.title }}</a
                    >
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </a-table-column>
          <a-table-column key="value" data-index="value">
            <template slot="title" slot-scope="{ setSelectedKeys, selectedKeys }">
              <!-- <a-select v-model="reasonType" style="width: 150px" @change="getTableData">
                <a-select-option v-for="item of tableOption2" :key="item.value" :value="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select> -->
              <a-dropdown>
                <a @click="(e) => e.preventDefault()" style="color: #2F54EB;">
                  {{ tableOption2.find(x => x.value == reasonType)?.name }} <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item v-for="item in tableOption2" :key="item.value">
                    <a
                      href="javascript:;"
                      @click="() => {
                        reasonType = item.value;
                        getTableData(reasonType);
                      }"
                      >{{ item.name }}</a
                    >
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </a-table-column>
      </a-table>
    </white-card>
  </div>
</template>

<script>
import BaseChart from "@/components/echarts/BaseChart";
import WhiteCard from "@/components/WhiteCard";
import * as echarts from "echarts";

const tableOption2 = [
  {
    value: 17,
    name: '满意的原因',
  },
  {
    value: 18,
    name: '不满意原因',
  },
]
const tableOption1 = [
  {
    value: 3,
    title: '推荐者',
  },
  {
    value: 2,
    title: '中立者',
  },
  {
    value: 1,
    title: '贬损者',
  }
];
const dataSource = [{
  title: '',
  value: '',
  id: 1,
}]
export default {
  name: "InformationTabContent",
  components: {BaseChart, WhiteCard },
  props: {
    id : {
      type: String,
      default: '',
    },
    searchValue: {
      type: Object,
      default: () => {
        return {
          group : '',
          shop : '',
          month : [],
        }
      },
    }
  },
  data() {
    return {
      option:{},
      option1:{},
      option2:{},
      // columns,
      tableOption2,
      tableOption1,
      dataSource,
      type: 3,
      reasonType: 17,
      loading: false,
      total: 0, // 总数量
      pageNum: 0, // 总页数
      page: 0, // 表格当前页面
      per_page: 10, // 当前页面的展示数量条数
    }
  },
  watch: {
    searchValue: {
      handler(newVal, oldVal) {
        if (oldVal !== newVal) {
          this.getChartData1();
          this.getChartData2();
          this.getChartData3();
          this.getTableData();
        }
      },
      immediate: false,
      deep: true,
    },
  },
  methods: {
    getChartData1(){
      this.option = null;
      this.$axios.post('/api/dada/satisfaction_t2b',{
        sn: this.id,
        complete_start_time: this.searchValue.month[0],
        complete_end_time: this.searchValue.month[1],
        shop_name: this.searchValue.shop,
      }).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          const result = res.data.data.findIndex(item => item.value - 0 > 0)
          if(result !== -1){
            this.initOptions1(res.data.data);
          }
        }
      })
    },
    getChartData2(){
      this.option1 = null;
      this.$axios.post('/api/dada/satisfaction_t2b_crowd',{
        sn: this.id,
        complete_start_time: this.searchValue.month[0],
        complete_end_time: this.searchValue.month[1],
        shop_name: this.searchValue.shop,
      }).then((res) => {
        console.log(res);
        if (res.data.code === 0){
          const result = res.data.data.findIndex(item => item.value - 0 > 0)
          if(result !== -1){
            this.initOptions2(res.data.data);
          };
        }
      })
    },
    getChartData3(){
      this.option2 = null;
      this.$axios.post('/api/dada/satisfaction_b2b',{
        sn: this.id,
        complete_start_time: this.searchValue.month[0],
        complete_end_time: this.searchValue.month[1],
        shop_name: this.searchValue.shop,
      }).then((res) => {
        console.log(res);
        if (res.data.code === 0){
          const result = res.data.data.findIndex(item => item.value - 0 > 0)
          if(result !== -1){
            this.initOptions3(res.data.data);
          }
        }
      })
    },
    getTableData(){
      this.dataSource = [];
      this.$axios.post('/api/dada/satisfaction_t2b_blank',{
        sn: this.id,
        complete_start_time: this.searchValue.month[0],
        complete_end_time: this.searchValue.month[1],
        shop_name: this.searchValue.shop,
        type: this.type,
        question_index: this.reasonType,
        page: 1,
        per_page: 10,
      }).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          res.data.data.data.map((item, index) => {
            let sitem = item;
            sitem.id = index + 1;
            this.dataSource.push(sitem);
          })
        }
      })
    },
    initOptions1(data) {
      let xarr = [];
      let yarr = [];
      data.map(item => {
        xarr.push(item.title);
        yarr.push(item.value);
      });
      this.option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          valueFormatter: function (value) {
            return value + '%';
            // return `${(Number((value * 100).toFixed(2)) )}%`;
          },
        },
        grid: {
          top: 30,
          left: 50,
          right: 50,
          height: 300,
        },
        xAxis: {
          type: 'category',
          data: xarr,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter(value){
              return value + '%';
              // return `${(Number((value * 100).toFixed(2)) )}%`;
            }
          },
        },
        series: [
          {
            data: yarr,
            type: 'bar',
            barMaxWidth: '100',
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#6BC6F7' },
              { offset: 1, color: '#5981F7' },
            ]),
            label: {
              show: true,
              position: 'top',
              formatter: function (value) {
                return value.value + '%';
                // return `${(Number((value.value * 100).toFixed(2)) )}%`;
              },
            },
          }
        ]
      };
    },
    initOptions2(data) {
      let xarr = [];
      let yarr = [];
      data.map(item => {
        xarr.push(item.value);
        yarr.push(item.title);
      });
      this.option1 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          valueFormatter: function (value) {
            return value + '%';
            // return `${(Number((value * 100).toFixed(2)) )}%`;
          },
        },
        legend: {
          show: false
        },
        grid: {
          top: '30',
          left: '50',
          right: '50',
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLabel: {
            formatter(value){
              return value + '%';
              // return `${(Number((value * 100).toFixed(2)) )}%`;
            }
          },
        },
        yAxis: {
          type: 'category',
          data: yarr,
        },
        series: [
          {
            type: 'bar',
            data: xarr,
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
              { offset: 0, color: '#5981F7' },
              { offset: 1, color: '#6BC6F7' },
            ]),
            label: {
              show: true,
              position: 'right',
              formatter: function (value) {
                return value.value + '%';
                // return `${(Number((value.value * 100).toFixed(2)) )}%`;
              },
            },
          },
        ]
      };
    },
    initOptions3(data) {
      let xarr = [];
      let yarr = [];
      data.map(item => {
        xarr.push(item.title);
        yarr.push(item.value);
      })
      this.option2 = {
        grid: {
          top: 30,
          left: 50,
          right: 50,
          height: 300,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          valueFormatter: function (value) {
            return value + '%';
            // return `${(Number((value * 100).toFixed(2)) )}%`;
          },
        },
        xAxis: {
          type: 'category',
          data: xarr,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter(value){
              return value + '%';
              // return `${(Number((value * 100).toFixed(2)) )}%`;
            }
          },
        },
        series: [
          {
            data: yarr,
            type: 'bar',
            barMaxWidth: '100',
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#6BC6F7' },
              { offset: 1, color: '#5981F7' },
            ]),
            label: {
              show: true,
              position: 'top',
              formatter: function (value) {
                return value.value + '%';
                // return `${(Number((value.value * 100).toFixed(2)) )}%`;
              },
            },
          }
        ]
      };
    },
  },
  mounted() {
    this.getChartData1();
    this.getChartData2();
    this.getChartData3();
    this.getTableData();
  },
}
</script>

<style scoped lang="scss">
.information-wrapper{
  padding: 16px 24px 16px 24px;
  background-color: white;
  margin: 0 1px 0 1px;
  border-bottom-left-radius: 4px ;
  border-bottom-right-radius: 4px ;

}

::v-deep .card .card-top{
  border-bottom: unset !important;
}

::v-deep .ant-select-selection{
  border: unset;
  background-color: unset;
}
</style>
